import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useGetUserSubscriptionQuery } from 'store/api/usersApi'
import { CONFIG } from '../../constants'
import { getAuthToken } from '../../store/slices/authSlice'

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authToken: null,
        tier: null,
        userId: '',
    },
    reducers: {
        clearAuthState: (state) => {
            state.authToken = null
        },
        addAuthState: (state, action) => {
            state.authToken = action.payload
        },
        addUserId: (state, action) => {
            state.userId = action.payload
        },
    },
})

export const { addAuthState, clearAuthState, addUserId } = authSlice.actions

export const selectAuthToken = (state) => state.auth.authToken
export const selectUserId = (state) => state.auth.userId
export const selectFeatureFlags = (state) => {
    const id = selectUserId(state)
    const featureFlagUserIds = CONFIG.FEATURE_FLAGS.COLOR_MANAGEMENT_PRESETS
    if (featureFlagUserIds.includes(id)) {
        return {
            canAccessColorManagementPresets: true,
        }
    }
    return {
        canAccessColorManagementPresets: false,
    }
}

export default authSlice.reducer
