import cn from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Icon from '../Icon'
import styles from './Header.module.sass'

import User from './User'

const Header = ({ onOpen }) => {
    const [visible, setVisible] = useState(false)

    const handleClick = () => {
        onOpen()
        setVisible(false)
    }

    const { isSidebarExpanded } = useSelector((state) => state.display)

    return (
        <header
            className={cn(styles.header, {
                [styles.toggleSidebarDesktop]: !isSidebarExpanded,
            })}
        >
            <button className={styles.burger} onClick={() => handleClick()} />
            {/*<Search className={cn(styles.search, { [styles.visible]: visible })} />*/}
            <button
                className={cn(styles.buttonSearch, {
                    [styles.active]: visible,
                })}
                onClick={() => setVisible(!visible)}
            >
                <Icon name='search' size='24' />
            </button>

            <div className={styles.control} onClick={() => setVisible(false)}>
                {/* <Messages className={styles.messages} />
        <Notification className={styles.notification} /> */}
                <User className={styles.user} />
            </div>
            {/* <div className={styles.btns}>
        <Link className={styles.link} to="/sign-in">
          Sign in
        </Link>
        <Link className={cn("button", styles.button)} to="/sign-up">
          Sign up
        </Link>
      </div> */}
        </header>
    )
}

export default Header
