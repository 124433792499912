const MB = 1024 * 1024
const GB = 1024 * MB
const MiB = 1024 * 1024
const GiB = 1024 * MiB

const LOCAL_CONFIG = {
    MULTIPART_UPLOAD_CHUNK_SIZE: 5 * MB,
    MULTIPART_SIZE_MINIMUM: 0.2 * GB,
    FEATURE_FLAGS: {
        COLOR_MANAGEMENT_PRESETS: [
            'google-oauth2|102750304997037641579', // SH
            //'google-oauth2|108023785275756072142', // SH-2
            'google-oauth2|107966522498783434098', // EB
            'auth0|66434083fcacdcaa7fdbbf14', // JA
            'google-oauth2|117043142988903087818', // KB
        ],
    },
}

const DEVELOPMENT_CONFIG = {
    MULTIPART_UPLOAD_CHUNK_SIZE: 50 * MB,
    MULTIPART_SIZE_MINIMUM: 0.5 * GB,
    FEATURE_FLAGS: {
        COLOR_MANAGEMENT_PRESETS: [
            'google-oauth2|102750304997037641579', // SH
            //'google-oauth2|108023785275756072142', // SH-2
            'google-oauth2|107966522498783434098', // EB
            'auth0|66434083fcacdcaa7fdbbf14', // JA
            'google-oauth2|117043142988903087818', // KB
        ],
    },
}

const PRODUCTION_CONFIG = {
    MULTIPART_UPLOAD_CHUNK_SIZE: 100 * MB,
    MULTIPART_SIZE_MINIMUM: 4 * GB,
    FEATURE_FLAGS: {
        COLOR_MANAGEMENT_PRESETS: [
            'google-oauth2|104267274683232600433', // VUK
            'auth0|664be1972f82e38f6d8b5ff2', // cheuq
            'google-oauth2|102750304997037641579', // SH
            'google-oauth2|107966522498783434098', // EB
            'google-oauth2|117043142988903087818', // KB
            'auth0|66434083fcacdcaa7fdbbf14', // JA
        ],
    },
}

const CONFIG = (() => {
    const isLocal = window.location.hostname === 'localhost'
    const isDevelopment = window.location.hostname.includes(
        'development.app.renderjuice.com',
    )
    const isProduction = window.location.hostname.includes(
        'app.renderjuice.com',
    )
    console.log(
        `ENVIRONMENT: ${
            isLocal ? 'LOCAL' : isDevelopment ? 'DEVELOPMENT' : 'PRODUCTION'
        }`,
    )
    if (isLocal) return LOCAL_CONFIG
    if (isDevelopment) return DEVELOPMENT_CONFIG
    if (isProduction) return PRODUCTION_CONFIG
    return LOCAL_CONFIG
    //throw new Error('No config found for this environment')
})()

export default CONFIG
