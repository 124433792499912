import * as Sentry from '@sentry/react'
import Loader from 'components/Loader'
import Page from 'components/Page'
import Up from 'components/RenderFileDropzone/uppy'
import Signup from 'components/Signup'
import useAuthMount from 'hooks/useAuthMount'
import useSegmentOnLocationChange from 'hooks/useSegmentOnLocationChange'
import { Suspense, lazy, useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import analytics, { EVENTS } from 'utils/analytics'
import { ProtectedRoute, SubscribedRoute } from './RouteTypes'

const PaymentSuccess = () => {
    const history = useHistory()
    useEffect(() => {
        analytics.track(EVENTS.CHECKOUT_COMPLETED)
        console.log('Payment success')
        history.push('/')
    })
    return null
}

const PaymentCancel = () => {
    const history = useHistory()
    useEffect(() => {
        analytics.track(EVENTS.CHECKOUT_CANCELLED)
        console.log('Payment cancel')
        history.push('/')
    })
    return null
}

const StyledLoader = styled(Loader)`
  margin: auto;
`

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Renders = lazy(() => import('screens/Renders'))
const RenderConfig = lazy(() => import('screens/RenderConfig'))
const Validating = lazy(() => import('screens/Validating'))
const Rendering = lazy(() => import('screens/Rendering'))
const Credits = lazy(() => import('screens/Credits'))
const UploadRenderFile = lazy(() => import('screens/UploadRenderFile'))
const UpgradeToPro = lazy(() => import('screens/UpgradeToPro'))

const SwitchRoutes = () => {
    useAuthMount()
    useSegmentOnLocationChange()

    return (
        <Page>
            <Suspense
                fallback={
                    <Container>
                        <StyledLoader />
                    </Container>
                }
            >
                <Switch>
                    <Route
                        exact
                        path='/upgrade-to-pro'
                        component={UpgradeToPro}
                    />
                    <Route exact path='/uppy' component={Up} />
                    <ProtectedRoute exact path='/renders' component={Renders} />
                    <SubscribedRoute
                        exact
                        path='/jobs/:id/config'
                        component={RenderConfig}
                    />
                    <SubscribedRoute
                        exact
                        path='/jobs/:id/validation'
                        component={Validating}
                    />
                    <SubscribedRoute
                        exact
                        path='/jobs/:id/status'
                        component={Rendering}
                    />
                    <SubscribedRoute
                        exact
                        path='/credits'
                        component={Credits}
                    />
                    <Sentry.ErrorBoundary
                        beforeCapture={(scope) => {
                            scope.setTag('screen', 'UploadRenderFile')
                        }}
                    >
                        <ProtectedRoute
                            exact
                            path='/'
                            component={UploadRenderFile}
                        />
                    </Sentry.ErrorBoundary>
                    <ProtectedRoute exact path='/login' component={Signup} />
                    <ProtectedRoute
                        exact
                        path='/signup'
                        component={Signup}
                        options={{
                            screen_hint: 'signup',
                            prompt: 'login',
                        }}
                    />
                    {/* <ProtectedRoute exact path='/pagelist' component={PageList} /> */}
                    <Route
                        exact
                        path='/payment-success'
                        component={PaymentSuccess}
                    />
                    <Route
                        exact
                        path='/payment-cancel'
                        component={PaymentCancel}
                    />
                </Switch>
            </Suspense>{' '}
        </Page>
    )
}

export default SwitchRoutes
