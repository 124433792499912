import AwsS3 from '@uppy/aws-s3'
import Uppy from '@uppy/core'
import { ReduxStore } from '@uppy/store-redux'
import axios from 'axios'
import { store } from '../'
import { CONFIG } from '../../constants'
import { MiB, shouldUseMultipart } from './utils'

const STORAGE_BASE_API_URL = `${process.env.REACT_APP_API_URL}/api/storage`
const axiosClient = axios.create({
    baseURL: STORAGE_BASE_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

/**
 * This generator transforms a deep object into URL-encodable pairs
 * to work with `URLSearchParams` on the client and `body-parser` on the server.
 */
function* serializeSubPart(key, value) {
    if (typeof value !== 'object') {
        yield [key, value]
        return
    }
    if (Array.isArray(value)) {
        for (const val of value) {
            yield* serializeSubPart(`${key}[]`, val)
        }
        return
    }
    for (const [subkey, val] of Object.entries(value)) {
        yield* serializeSubPart(key ? `${key}[${subkey}]` : subkey, val)
    }
}
function serialize(data) {
    // If you want to avoid preflight requests, use URL-encoded syntax:
    return new URLSearchParams(serializeSubPart(null, data))
    // If you don't care about additional preflight requests, you can also use:
    // return JSON.stringify(data)
    // You'd also have to add `Content-Type` header with value `application/json`.
}

/**
 * To find all of Uppy's methods and properties see docs:
 * https://uppy.io/docs/uppy/#new-uppyoptions
 */

const uppy = new Uppy({
    id: 'redux',
    store: new ReduxStore({ store, id: 'renderUpload' }),
    debug: false,
    // Enable duplicate files to be uploaded
    onBeforeFileAdded: (file) => {
        return true
    },
}).use(AwsS3, {
    id: 'myAWSPlugin',
    shouldUseMultipart,
    /*
     * THIS FIELD IS REQUIRED FOR SOME UNDOCUMENTED REASON
     * TO DO PARALLEL UPLOADS
     * Limit must not be defined or set to zero
     * This contradicts what the docs say, but this `limit` field DOES
     * control whether concurrent uploads are enabled for a single file
     */
    limit: 0,
    // Size of each chunk of data to send to the server
    //
    getChunkSize: (file) => {
        // We use a larger chunk size, the minimum is 5MiB
        // to reduce overhead of requests since each
        // http request is billed for.
        return CONFIG.MULTIPART_UPLOAD_CHUNK_SIZE
    },

    /**
     * This method tells Uppy how to retrieve a temporary token for signing on the client.
     * Signing on the client is optional, you can also do the signing from the server.
     */
    async getTemporarySecurityCredentials({ signal }) {
        const authToken = uppy.getState().meta.authToken
        try {
            const ONE_HOUR = 60 * 60
            const durationSeconds = ONE_HOUR * 4
            const body = {
                durationSeconds,
            }
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                signal,
            }
            const response = await axiosClient.post('/token', body, config)
            const data = response?.data
            return data
        } catch (e) {
            throw new Error('Unsuccessful request', { cause: e })
        }
    },

    // ========== Non-Multipart Uploads ==========

    /**
     * This method tells Uppy how to handle non-multipart uploads.
     * If for some reason you want to only support multipart uploads,
     * you don't need to implement it.
     */
    async getUploadParameters(file, options) {
        const authToken = uppy.getState().meta.authToken

        const body = serialize({
            fileName: file.name,
        })
        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                accept: 'application/json',
            },
            signal: options.signal,
        }

        const response = await axios.post(
            `${STORAGE_BASE_API_URL}/signed-url`,
            body,
            config,
        )

        if (response.status !== 200)
            throw new Error('Unsuccessful request', { cause: response })

        const { data } = response
        const { url } = data

        // Return an object in the correct shape.
        return {
            method: 'PUT',
            url,
            fields: {}, // For presigned PUT uploads, this should be left empty.
            // Provide content type header required by S3
            headers: {
                'Content-Type': file.type,
            },
        }
    },

    // ========== Multipart Uploads ==========

    // The following methods are only useful for multipart uploads:
    // If you are not interested in multipart uploads, you don't need to
    // implement them (you'd also need to set `shouldUseMultipart: false` though).

    async createMultipartUpload(file, signal) {
        const authToken = uppy.getState().meta.authToken
        signal?.throwIfAborted()

        const metadata = {}

        Object.keys(file.meta || {}).forEach((key) => {
            /*
             * Add any meta fields added to the metadata payload except
             * JWT bearer auth token
             */
            if (file.meta[key] != null && file.meta[key] !== 'authToken') {
                metadata[key] = file.meta[key].toString()
            }
        })

        const body = serialize({
            fileName: file.name,
            type: file.type,
            metadata,
        })

        try {
            const response = await axiosClient.post('/s3/multipart', body, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type':
                        'application/x-www-form-urlencoded;charset=UTF-8',
                },
                signal,
            })

            return response.data
        } catch (e) {
            throw new Error('Unsuccessful request', { cause: e })
        }
    },

    async abortMultipartUpload(file, { key, uploadId }, signal) {
        const authToken = uppy.getState().meta.authToken
        const fileName = encodeURIComponent(key)
        const uploadIdEnc = encodeURIComponent(uploadId)
        const response = await axiosClient.delete(
            `/s3/multipart/${uploadIdEnc}?key=${fileName}`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                signal,
            },
        )

        if (!response.ok)
            throw new Error('Unsuccessful request', { cause: response })
    },

    async signPart(file, options) {
        // We currently sign from the client because CRA bundles `crypto` module to FE
        // and we might as well use it to sign the parts
        if (typeof crypto?.subtle === 'object') {
            // If WebCrypto, let's do signing from the client.
            const signedUrl = uppy
                .getPlugin('myAWSPlugin')
                .createSignedURL(file, options)
            return signedUrl
        }

        const { uploadId, key, partNumber, signal } = options

        signal?.throwIfAborted()

        if (uploadId == null || key == null || partNumber == null) {
            throw new Error(
                'Cannot sign without a key, an uploadId, and a partNumber',
            )
        }

        const fileName = encodeURIComponent(key)
        try {
            const response = await axiosClient.get(
                `/s3/multipart/${uploadId}/${partNumber}?key=${fileName}`,
                { signal },
            )

            const data = response.data

            return data
        } catch (e) {
            throw new Error('Unsuccessful request', { cause: e })
        }
    },
    async listParts(file, { key, uploadId }, signal) {
        signal?.throwIfAborted()

        const fileName = encodeURIComponent(key)
        try {
            const response = await axiosClient.get(
                `/s3/multipart/${uploadId}?key=${fileName}`,
                { signal },
            )

            const data = response.data
            return data
        } catch (e) {
            throw new Error('Unsuccessful request', { cause: e })
        }
    },
    /**
     * We use a `simple` request to avoid preflight of this
     * request during multipart uploads to avoid overloading the server
     *
     * This is done via the `serialize` methods and using the form-urlencoding content type
     * @param {} file
     * @param {*} param1
     * @param {*} signal
     * @returns
     */
    async completeMultipartUpload(file, { key, uploadId, parts }, signal) {
        const authToken = uppy.getState().meta.authToken
        signal?.throwIfAborted()

        const fileName = encodeURIComponent(key)
        const uploadIdEnc = encodeURIComponent(uploadId)
        try {
            const response = await axiosClient.post(
                `/s3/multipart/${uploadIdEnc}/complete?key=${fileName}`,
                serialize({ parts }),
                {
                    headers: {
                        'Content-Type':
                            'application/x-www-form-urlencoded;charset=UTF-8',
                        accept: 'application/json',
                        Authorization: `Bearer ${authToken}`,
                    },
                    signal,
                },
            )

            const data = response.data
            return data
        } catch (error) {
            throw new Error('Unsuccessful request', { cause: error.response })
        }
    },
})

export { uppy }
